import HeroSection from "./components/HeroSection";
import FeaturesSection from "./components/FeaturesSection";
import AboutSection from "./components/AboutSection";
import React from "react";
import Footer from "../../shared/components/Footer";

const HomePage = () => {
    return (
        <>
            <HeroSection />
            {/*<OpenAIAssistant />*/}
            <FeaturesSection />
            <AboutSection />
            <section className="testimonials">
                <div className="container">
                    <h2>AI Assistant</h2>
                    <p>Revolutionary AI assistant for dentist websites. Improve user experience and efficiency.</            p>
                    <div className="testimonial">
                        <p>★★★★★</p>
                        <p>The AI assistant on my dentist's website is a game-changer. It helped me find all the information I needed quickly and efficiently.</p>
                        <p><strong>John Doe</strong>, New York</p>
                    </div>
                    <div className="testimonial">
                        <p>★★★★★</p>
                        <p>Impressed with the AI assistant on my dentist's website. It made scheduling appointments so easy!</p>
                        <p><strong>Sara Smith</strong>, Dallas</p>
                    </div>
                </div>
            </section>
            {/*<ContactFormSection />*/}
            <Footer />
        </>
    );
}

export default HomePage;