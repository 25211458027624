import React from 'react';
import '../../../App.css';

const FeaturesSection = () => {
    return (
        <section>
            <h1 className="section-features-title">Dentist AI Assistant</h1>
            <p className="section-features-description">Experience the future of dentistry with our AI assistant on your dentist website.</p>
            <div className="features">
                <div className="feature-card">
                    <img src="https://images.unsplash.com/photo-1486049125644-f35e226a5e14?ixid=M3wzOTE5Mjl8MHwxfHNlYXJjaHwyNHx8RGVudGFsJTIwc2VydmljZXN8ZW58MHx8fHwxNzIwNDQxMDgwfDA&ixlib=rb-4.0.3&auto=format&fit=crop&w=1080&h=738" alt="Technology Solutions" />
                    <h3>Innovative Technology Solutions</h3>
                    <p>Our AI assistant provides cutting-edge technology solutions for your dental practice's website.</p>
                </div>
                <div className="feature-card">
                    <img src="https://images.unsplash.com/photo-1468493858157-0da44aaf1d13?ixid=M3wzOTE5Mjl8MHwxfHNlYXJjaHw2fHxEZW50YWwlMjBzZXJ2aWNlc3xlbnwwfHx8fDE3MjA0NDEwODB8MA&ixlib=rb-4.0.3&auto=format&fit=crop&w=1080&h=734" alt="User Experience" />
                    <h3>Enhanced User Experience</h3>
                    <p>Improve user experience on your dentist website with our AI assistant's advanced features.</p>
                </div>
                <div className="feature-card">
                    <img src="https://images.unsplash.com/photo-1588776814546-1ffcf47267a5?ixid=M3wzOTE5Mjl8MHwxfHNlYXJjaHwxMnx8RGVudGFsJTIwc2VydmljZXN8ZW58MHx8fHwxNzIwNDQxMDgwfDA&ixlib=rb-4.0.3&auto=format&fit=crop&w=1080&h=738" alt="Patient Communication" />
                    <h3>Efficient Patient Communication</h3>
                    <p>Streamlined Workflow Management</p>
                </div>
            </div>
        </section>
    );
};

export default FeaturesSection;