import {useNavigate} from "react-router-dom";

const Header = ({isAuthenticated}) => {
    const navigate = useNavigate();

    return (
        <header className="header">
            <div className="header-left">
                Dentist
            </div>
            <div className="header-right">
                <button className="nav-button" onClick={() => navigate("/")}>Home</button>
                {isAuthenticated ?
                    <button className="nav-button" onClick={() => navigate("/signin")}>LogIn</button>
                    :
                    <button className="nav-button" onClick={() => navigate("/assistant")}>Assistant</button>
                }
            </div>
        </header>
    );
};

export default Header;