import "../../../App.css";
const AboutSection = () => {
    return (
        <section className="about-section-container">
            <div className="text-section">
                <h1>Leading AI Dentist Website Platform</h1>
                <p>
                    Our platform revolutionizes the way dentists interact with patients, providing
                    cutting-edge AI technology to enhance the patient experience and streamline operations.
                    With our user-friendly interface, dentists can easily showcase their services and connect
                    with patients in a whole new way.
                </p>
                <div className="stats">
                    <div className="stat-item">
                        <h2>150+</h2>
                        <p>AI Assistant</p>
                    </div>
                    <div className="stat-item">
                        <h2>15</h2>
                        <p>Innovative Solution Provider</p>
                    </div>
                </div>
            </div>
            <div className="image-section">
                <img src="https://images.unsplash.com/photo-1495573020741-8a2f372bbec3?ixid=M3wzOTE5Mjl8MHwxfHNlYXJjaHw3fHxEZW50YWwlMjBzZXJ2aWNlc3xlbnwwfHx8fDE3MjA0NDEwODB8MA&ixlib=rb-4.0.3&auto=format&fit=clip&w=328&q=100" alt="Dental Equipment" />
            </div>
        </section>
    );
}

export default AboutSection;