import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import CardDataInput from "./components/CardDataInput";

const SignUp = () => {
    const navigate = useNavigate();

    const [cardDetails, setCardDetails] = useState({
        card_number: '',
        expiry_month: '',
        expiry_year: '',
        cvv: '',
        cardholder_name: '',
        billing_address_line1: '',
        billing_address_line2: '',
        billing_city: '',
        billing_state: '',
        billing_zip: '',
        billing_country: 'Ukraine',
        phone_country_code: '+380',
        phone_number: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCardDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const {
            card_number,
            expiry_month,
            expiry_year,
            cvv,
            cardholder_name,
            billing_address_line1,
            billing_address_line2,
            billing_city,
            billing_state,
            billing_zip,
            billing_country,
            phone_country_code,
            phone_number
        } = cardDetails;

        const requestData = {
            card_number,
            expiry_month,
            expiry_year,
            cvv,
            cardholder_name,
            billing_address: {
                address_line1: billing_address_line1,
                address_line2: billing_address_line2,
                city: billing_city,
                state: billing_state,
                zip: billing_zip,
                country: billing_country
            },
            phone: {
                country_code: phone_country_code,
                number: phone_number
            }
        };
        try {
            const response = await axios.post('api/records/register.php', requestData);
            // alert(response.data.message || 'Request processed successfully');
        } catch (error) {
            console.error('There was an error!', error);
        }
    };

    return (
        <div style={{ padding: '20px' }}>
            <form onSubmit={handleSubmit} className="sign-up">
                <div className="card-number-card">
                    <input type="text" className="card-number" name="card_number"
                           placeholder="1234 1234 1234 1234"
                           value={cardDetails.card_number}
                           onChange={handleChange} />
                    <div className="card-details">
                        <input type="number" className="month-year" placeholder="MM / YY"
                               name="expiry_month"
                               value={cardDetails.expiry_month}
                               onChange={handleChange} />
                        <input type="number" className="cvv" placeholder="CVV"
                               name="cvv"
                               value={cardDetails.cvv}
                               onChange={handleChange}/>
                    </div>
                </div>
                {/*<div className="card-nummer-card">*/}
                {/*    <input*/}
                {/*        type="text"*/}
                {/*        name="card_number"*/}
                {/*        value={cardDetails.card_number}*/}
                {/*        onChange={handleChange}*/}
                {/*        placeholder="1234 1234 1234 1234"*/}
                {/*        required*/}
                {/*        id="card-number"*/}
                {/*    />*/}
                {/*    <input*/}
                {/*        type="text"*/}
                {/*        name="expiry_month"*/}
                {/*        value={cardDetails.expiry_month}*/}
                {/*        onChange={handleChange}*/}
                {/*        placeholder="Expiry Month"*/}
                {/*        required*/}
                {/*        id="expiry_month"*/}
                {/*    />*/}
                {/*    <input*/}
                {/*        type="text"*/}
                {/*        name="expiry_year"*/}
                {/*        value={cardDetails.expiry_year}*/}
                {/*        onChange={handleChange}*/}
                {/*        placeholder="Expiry Year"*/}
                {/*        required*/}
                {/*        id="expiry_year"*/}
                {/*    />*/}
                {/*    <input*/}
                {/*        type="text"*/}
                {/*        name="cvv"*/}
                {/*        value={cardDetails.cvv}*/}
                {/*        onChange={handleChange}*/}
                {/*        placeholder="CVV"*/}
                {/*        required*/}
                {/*        id="cvv"*/}
                {/*    />*/}
                {/*</div>*/}
                <input
                    type="text"
                    name="cardholder_name"
                    value={cardDetails.cardholder_name}
                    onChange={handleChange}
                    placeholder="Ім'я власника картки"
                    required
                />
                <input
                    type="text"
                    name="billing_address_line1"
                    value={cardDetails.billing_address_line1}
                    onChange={handleChange}
                    placeholder="Адреса, рядок 1"
                    required
                />
                <input
                    type="text"
                    name="billing_address_line2"
                    value={cardDetails.billing_address_line2}
                    onChange={handleChange}
                    placeholder="Адреса, рядок 2"
                    required
                />
                <input
                    type="text"
                    name="billing_city"
                    value={cardDetails.billing_city}
                    onChange={handleChange}
                    placeholder="Місто"
                    required
                />
                <input
                    type="text"
                    name="billing_state"
                    value={cardDetails.billing_state}
                    onChange={handleChange}
                    placeholder="Область"
                    required
                />
                <input
                    type="text"
                    name="billing_zip"
                    value={cardDetails.billing_zip}
                    onChange={handleChange}
                    placeholder="Поштовий код"
                    required
                />
                <input
                    type="text"
                    name="billing_country"
                    // value={cardDetails.billing_country}
                    // onChange={handleChange}
                    placeholder="Україна"
                    disabled={true}
                    required
                />
                <div className="phone-card">
                    <input
                        type="number"
                        name="phone_country_code"
                        // value={cardDetails.phone_country_code}
                        value={"+380"}
                        // onChange={handleChange}
                        placeholder="+380"
                        disabled={true}
                        // required
                    />
                    <input
                        type="number"
                        name="phone_number"
                        value={cardDetails.phone_number}
                        onChange={handleChange}
                        placeholder="91 123 45 67"
                        required
                    />
                </div>
                <button type="submit">Зареєструватись</button>
                <p id="enter-text">Вже маєш аккаунт? <span onClick={() => navigate("/signin")} id="sign-text">Увійти</span></p>
            </form>
        </div>
    );
};

export default SignUp;
