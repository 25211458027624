import React from 'react';
import '../../../App.css';

const HeroSection = () => {
    return (
        <section className="hero-section">
            <div className="hero-content"></div>
            <div className="hero-content-data">
                <h1>AI Dentist Website Assistant</h1>
                <p>Transforming Your Dental Experience with Cutting-Edge AI Technology. See it in Action Today!</p>
                <button className="hero-button">Explore</button>
            </div>

        </section>
    );
};

export default HeroSection;
