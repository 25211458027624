import React, {useState} from 'react';
import "./style.css";
import axios from "axios";

const Assistant = () => {
    const [input, setInput] = useState('');
    const [messages, setMessages] = useState([]);
    // const [runId, setRunId] = useState('');
    const assistantId = 'asst_OpbWP5IxAD1LEnT1trj0tfiz'; // Replace with your assistant_id
    const threadId = 'thread_7cFn9lMkcBxhrQ3czrv1YsFg'; // Replace with your thread_id
    const apiKey = 'sk-proj-h5NbkzYeVuQwvIdHAVjrT3BlbkFJKJ3YO55r3wp5earAx2xz'; // Replace with your OpenAI API key

    const [buttonClicked, setButtonClicked] = useState(false);

    const updateLastMessage = (newMessage) => {
        let index = 0;
        const intervalId = setInterval(() => {
            if (index < newMessage.length - 1) {
                setMessages((prevMessages) => {
                    const newMessages = [...prevMessages];
                    newMessages[newMessages.length - 1].text += newMessage[index];
                    index++;
                    return newMessages;
                });
            } else {
                clearInterval(intervalId);
            }
        }, 50);
    };

    const handleGet = async (runId, retryCount = 10) => {
        if (retryCount === 0) {
            setMessages([...messages, { text: 'Помилка, спробуйте ще раз', sender: 'server' }]);
            return;
        }
        try {
            const result = await axios.get(
                `https://api.openai.com/v1/threads/${threadId}/messages`,
                //             `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`,
                {
                    headers: {
                        Authorization: `Bearer ${apiKey}`,
                        'Content-Type': 'application/json',
                        'OpenAI-Beta': 'assistants=v2'
                    },
                }
            );
            if (result && result.data && result.data.data) {
                console.log(`RunID: ${runId}`);
                console.log(result.data.data);
                const message = result.data.data.find(item => item.run_id === runId);
                if (message) {
                    const message_text = message.content[0].text.value;
                    setMessages((prevMessages) => [...prevMessages, { text: message_text[0], sender: 'server' }]);
                    const updatedMessage = message_text.substring(1);
                    console.log(updatedMessage);
                    updateLastMessage(updatedMessage);
                    return;
                }
            }
        } catch (e) {
            console.error('Error getting message:', e);
        }

        // Wait 2 seconds before retrying
        setTimeout(() => handleGet(runId, retryCount - 1), (11 - retryCount) * 2000);
    };

    const handleSend = async () => {
        if (!input) {
            return;
        }
        // Add user message
        setMessages((prevMessages) => [...prevMessages, { text: input, sender: 'user' }]);

        try {
            const result = await axios.post(
                `https://api.openai.com/v1/threads/${threadId}/runs`,
                {
                    assistant_id: assistantId,
                    instructions: input
                },
                {
                    headers: {
                        Authorization: `Bearer ${apiKey}`,
                        'Content-Type': 'application/json',
                        'OpenAI-Beta': 'assistants=v2'
                    },
                }
            );
            // Wait 2 seconds and then call handleGet
            setTimeout(() => handleGet(result.data.id), 2000);
            setInput("");
        } catch (error) {
            console.error('Error sending message:', error);
            setMessages((prevMessages) => [...prevMessages, { text: 'Error: Unable to get a response.', sender: 'server' }]);
        }
    };

    const handleButtonClick = async () => {
        setButtonClicked(true);
        await handleSend();
        setTimeout(() => {
            setButtonClicked(false);
        }, 1000); // Reset button after the animation
    };
    return (
        <div className="chat-app">
            <div className="chat-window">
                {messages.map((msg, index) => (
                    <div key={index} className={`chat-message ${msg.sender}`}>
                        {msg.text}
                    </div>
                ))}
            </div>
            <div className="chat-input-container">
                <textarea
                    className="chat-input"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    maxLength="500"
                    rows="1"
                    style={{ maxHeight: '10vh' }}
                />
                <button className="chat-send-button" onClick={handleButtonClick}>
                    Send
                </button>
            </div>
        </div>
    );
};

export default Assistant;