import './App.css';
import "./fonts/fonts.css";
import {Route, BrowserRouter as Router, Routes, Navigate} from "react-router-dom";
import HomePage from "./pages/HomePage/HomePage";
import SignIn from "./pages/SignInPage/SignInPage";
import SignUp from "./pages/SignUpPage/SignUpPage";
import Assistant from "./pages/Assistant/Assistant";
import React, {useEffect, useState} from "react";
import Header from "./shared/components/Header";

function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    useEffect(() => {
        const handleStorageChange = (event) => {
            if (event.key === 'token') {
                setIsAuthenticated(true);
                // setToken(event.newValue);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

  return (
    <div className="App">
        <Header isAuthenticated={isAuthenticated}/>
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route
              path="/assistant"
              element={
                !isAuthenticated ? <Assistant /> : <Navigate to="/signin" />
              }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

// curl https://api.openai.com/v1/threads \
//     -H "Content-Type: application/json" \
//   -H "Authorization: Bearer sk-proj-h5NbkzYeVuQwvIdHAVjrT3BlbkFJKJ3YO55r3wp5earAx2xz" \
//   -H "OpenAI-Beta: assistants=v2" \
//   -d ''

// {
//   "id": "thread_zf6ytNGVtI7HrEKz6H1jEQ4x",
//     "object": "thread",
//     "created_at": 1720527314,
//     "metadata": {},
//   "tool_resources": {}
// }

// curl https://api.openai.com/v1/threads/thread_zf6ytNGVtI7HrEKz6H1jEQ4x/messages \
//     -H "Content-Type: application/json" \
//   -H "Authorization: Bearer sk-proj-h5NbkzYeVuQwvIdHAVjrT3BlbkFJKJ3YO55r3wp5earAx2xz" \
//   -H "OpenAI-Beta: assistants=v2" \
//   -d '{
//     "role": "user",
//     "content": "Do I need to get veneers?"
// }'

// {
//   "id": "msg_iHb7NhO1fhTkeG826Z3Zj1SB",
//     "object": "thread.message",
//     "created_at": 1720527462,
//     "assistant_id": null,
//     "thread_id": "thread_zf6ytNGVtI7HrEKz6H1jEQ4x",
//     "run_id": null,
//     "role": "user",
//     "content": [
//   {
//     "type": "text",
//     "text": {
//       "value": "Do I need to get veneers?",
//       "annotations": []
//     }
//   }
// ],
//     "attachments": [],
//     "metadata": {}
// }

// curl https://api.openai.com/v1/threads/thread_zf6ytNGVtI7HrEKz6H1jEQ4x/runs \
//     -H "Authorization: Bearer sk-proj-h5NbkzYeVuQwvIdHAVjrT3BlbkFJKJ3YO55r3wp5earAx2xz" \
//   -H "Content-Type: application/json" \
//   -H "OpenAI-Beta: assistants=v2" \
//   -d '{
//     "assistant_id": "asst_OpbWP5IxAD1LEnT1trj0tfiz",
//     "instructions": "Do I need to get veneers or not?"
// }'

// curl https://api.openai.com/v1/threads/thread_7cFn9lMkcBxhrQ3czrv1YsFg/messages \
//     -H "Content-Type: application/json" \
//   -H "Authorization: Bearer sk-proj-h5NbkzYeVuQwvIdHAVjrT3BlbkFJKJ3YO55r3wp5earAx2xz" \
//   -H "OpenAI-Beta: assistants=v2"

// curl https://api.openai.com/v1/threads/thread_zf6ytNGVtI7HrEKz6H1jEQ4x/runs \
//     -H "Content-Type: application/json" \
//   -H "Authorization: Bearer sk-proj-h5NbkzYeVuQwvIdHAVjrT3BlbkFJKJ3YO55r3wp5earAx2xz" \
//   -H "OpenAI-Beta: assistants=v2"


// curl https://api.openai.com/v1/threads/thread_zf6ytNGVtI7HrEKz6H1jEQ4x/messages \
//     -H "Content-Type: application/json" \
//   -H "Authorization: Bearer sk-proj-h5NbkzYeVuQwvIdHAVjrT3BlbkFJKJ3YO55r3wp5earAx2xz" \
//   -H "OpenAI-Beta: assistants=v2" \
//   -d '{
//     "role": "user",
//     "content": "How does AI work? Explain it in simple terms."
// }'

// Create message
// curl https://api.openai.com/v1/threads/thread_vZn414J15giTLqKoOh8SJZLg/messages \
//     -H "Content-Type: application/json" \
//   -H "Authorization: Bearer sk-proj-h5NbkzYeVuQwvIdHAVjrT3BlbkFJKJ3YO55r3wp5earAx2xz" \
//   -H "OpenAI-Beta: assistants=v2" \
//   -d '{
//     "role": "user",
//     "content": "Як ти можеш мені дпомогти?"
// }'

// List messages
// curl https://api.openai.com/v1/threads/thread_vZn414J15giTLqKoOh8SJZLg/messages \
//     -H "Content-Type: application/json" \
//   -H "Authorization: Bearer sk-proj-h5NbkzYeVuQwvIdHAVjrT3BlbkFJKJ3YO55r3wp5earAx2xz" \
//   -H "OpenAI-Beta: assistants=v2"


